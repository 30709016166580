<template>
  <v-app>
    <AppBar />
    <v-main>
      <router-view/>
    </v-main>
    <v-footer app class="overline">
      v{{ version }}
      <v-spacer></v-spacer>
      Bloqs4Good 2021
    </v-footer>
  </v-app>
</template>

<script>
import AppBar from './components/AppBar/AppBar'
export default {
  name: 'App',
  components: { AppBar },
  data: () => ({
    version: process.env.VUE_APP_VERSION
  }),
  mounted () {
  },
  methods: {
  }
}
</script>
