<template>
  <div class="AppBar">
    <v-app-bar
      app
      class="overline"
    >
      <v-btn icon to="/" class="ml-2">
      <v-img :src="require('@/assets/logo.svg')" contain height="50px" max-width="100px"/>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text>
        Discover
      </v-btn>
      <v-btn text>
        Create
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>

import './_AppBar.scss'

export default {
  name: 'AppBar',
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
  }
}
</script>
