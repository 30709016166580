<template>
    <div class="Home">
      <v-container fluid>
        <v-row>
          <v-col align-self="center" align="center">
            <v-img :src="require('@/assets/logo.svg')" max-width="200"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_Home.scss'
export default {
  name: 'Home',
  data () {
    return {
    }
  }
}
</script>
